import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {darkGoogle} from '../../app/modules/Umap/components/googleMap/styles'

export const ChooseType = {
  search: 'Search',
  current: 'Current',
  choose: 'Choose',
  pinpoints: 'Pinpoints',
  event: 'Event',
} as const

export type ChooseTypeObject = typeof ChooseType[keyof typeof ChooseType]

export type MarkerType = 'Temp' | 'all' | 'uhome' | 'upoint' | 'TempSearch'

interface LatLng {
  lat: number
  lng: number
}

export interface IMarker {
  name: string
  type: MarkerType
  address: string
  place_id?: string
  id?: string | number
  rating?: number | string
  longitude: number
  latitude: number
  shared_pin_web?: number
  personal_pin_web?: number,
  invited_pin_web?: number,
  invited_user_id?: string,
  events_count?: number | string,
  pin_from_event?:number
}

export interface IPinPointEvent {
  id: number | string,
  location: string,
  latitude: number | string,
  longitude: number | string,
  name: string
}

interface IUMapSlice {
  currentLocation: LatLng
  center: LatLng | google.maps.LatLng
  selected?: ChooseTypeObject
  selectedMarker?: IMarker
  markers: IMarker[]
  directions?: google.maps.DirectionsResult
  tempMarkers: IMarker[]
  uFoodSelected?: IMarker
  sharedMarkers: IMarker[]
  personalMarkers: IMarker[]
  invitedMarkers: IMarker[]
  mapType: google.maps.MapTypeId | string
  foodMarkers: google.maps.places.PlaceResult[]
  appleFoodMarkers: IMarker[]
  styles: google.maps.MapTypeStyle[]
  osSystem: string
  pinpointEventDetails: IPinPointEvent[],
  selectedEventLocation:IMarker
}

const initialState: IUMapSlice = {
  currentLocation: {
    lat: 0,
    lng: 0,
  },
  center: {
    lat: 0,
    lng: 0,
  },
  markers: [],
  tempMarkers: [],
  pinpointEventDetails: [],
  sharedMarkers: [],
  personalMarkers: [],
  invitedMarkers: [],
  mapType: 'roadmap',
  foodMarkers: [],
  styles: darkGoogle,
  osSystem: '',
  appleFoodMarkers: [],
  selectedEventLocation:{
    name: '',
    type: 'Temp',
    address: '',
    longitude: 0,
    latitude: 0
  }

}

const UMapSlice = createSlice({
  name: 'UMap',
  initialState,
  reducers: {
    setCurrLocation: (state, action: PayloadAction<{isCenter?: boolean; location: LatLng}>) => {
      state.currentLocation = action.payload.location
      if (!!action.payload.isCenter) {
        state.center = action.payload.location
      }
    },
    setOsSystem: (state, action: PayloadAction<string>) => {
      state.osSystem = action.payload
    },
    setCenter: (state, action: PayloadAction<LatLng | google.maps.LatLng>) => {
      state.center = action.payload
    },
    chooseMapOptions: (state, action: PayloadAction<ChooseTypeObject | undefined>) => {
      state.selected = action.payload
    },
    selectUFoodOption: (state, action: PayloadAction<IMarker | undefined>) => {
      state.uFoodSelected = action.payload
    },
    addMarker: (state, action: PayloadAction<{place: IMarker; temp?: boolean; createEvent?: boolean}>) => {
      if (action.payload.createEvent) {
        state.markers = [action.payload.place]
      } else {
        if (action.payload.temp) {
          state.tempMarkers.push(action.payload.place)
        } else {
          state.markers.push(action.payload.place)
        }
      }
    },
    setDirections: (state, action: PayloadAction<google.maps.DirectionsResult>) => {
      state.directions = action.payload
    },
    setPinpointsEvent: (state, action: PayloadAction<IPinPointEvent[]>) => {
      state.pinpointEventDetails = action.payload
    },
    createMapDone: (state, action: PayloadAction<{id: string | number}>) => {
      state.markers.push({...state.tempMarkers[0], id: action.payload.id})
      state.tempMarkers = []
    },
    editMarkerDone: (state, action: PayloadAction<IMarker[]>) => {
      state.markers = action.payload
      state.tempMarkers = []
    },
    editSharedMarkers: (state, action: PayloadAction<IMarker[]>) => {
      state.sharedMarkers = action.payload
      state.personalMarkers = action.payload
      state.invitedMarkers = action.payload
    },
    deleteMarker: (state, action: PayloadAction<IMarker>) => {
      const place = action.payload
      state.markers = state.markers.filter((m) => m.place_id === place.place_id)
    },
    setMapType: (state, action: PayloadAction<google.maps.MapTypeId | string>) => {
      state.mapType = action.payload
    },
    setFoodMarkers: (state, action: PayloadAction<google.maps.places.PlaceResult[]>) => {
      state.foodMarkers = action.payload
    },
    setAppleFoodMarkers: (state, action: PayloadAction<IMarker[]>) => {
      state.appleFoodMarkers = action.payload
    },
    setMarkers: (state, action: PayloadAction<IMarker[]>) => {
      state.markers = action.payload.map((m) => ({
        ...m,
        latitude: Number(m.latitude),
        longitude: Number(m.longitude),
      }))
    },
    setSharedMarkers: (state, action: PayloadAction<IMarker[]>) => {
      state.sharedMarkers = action.payload.map((m) => ({
        ...m,
        latitude: Number(m.latitude),
        longitude: Number(m.longitude),
      }))
    },
    setPersonalMarkers: (state, action: PayloadAction<IMarker[]>) => {
      state.personalMarkers = action.payload.map((m) => ({
        ...m,
        latitude: Number(m.latitude),
        longitude: Number(m.longitude),
      }))
    },
    setInvitedMarkers: (state, action: PayloadAction<IMarker[]>) => {
      state.invitedMarkers = action.payload.map((m) => ({
        ...m,
        latitude: Number(m.latitude),
        longitude: Number(m.longitude),
      }))
    },
    setTempMarker: (state, action: PayloadAction<IMarker[]>) => {
      state.tempMarkers = action.payload.map((m) => ({
        ...m,
        latitude: Number(m.latitude),
        longitude: Number(m.longitude),
      }))
    },
    removeMarker: (state, action: PayloadAction<IMarker>) => {
      state.markers = state.markers.filter(
        (m) => m.place_id !== action.payload.place_id || m.id !== action.payload.id
      )
    },
    setSelectedMarker: (state, action: PayloadAction<IMarker | undefined>) => {
      state.selectedMarker = action.payload
    },
    setMapStyle: (state, action: PayloadAction<string>) => {
      const style = state.styles.find(
        (s) => s.elementType === 'geometry.fill' && s.featureType === 'landscape'
      )

      if (style) {
        style.stylers = [
          {
            color: action.payload,
          },
        ]
      } else {
        state.styles.push({
          elementType: 'geometry.fill',
          featureType: 'landscape',
          stylers: [
            {
              color: action.payload,
            },
          ],
        })
      }
    },
    setEmptyMarkerList:(state)=>{
      state.markers=[]

    },
    resetMapMarkers: (state) => {
      state.markers = []
      state.tempMarkers = []
      state.selectedMarker = undefined
    },
    addSelectEventLocationMarker: (state, action: PayloadAction<{place: IMarker;}>) => {

       state.selectedEventLocation=action.payload.place
     
    },
  },
})

export default UMapSlice.reducer

export const {
  setCurrLocation,
  setCenter,
  chooseMapOptions,
  addMarker,
  setDirections,
  editMarkerDone,
  createMapDone,
  setMapType,
  setFoodMarkers,
  setTempMarker,
  deleteMarker,
  setSelectedMarker,
  removeMarker,
  setMarkers,
  setMapStyle,
  selectUFoodOption,
  setOsSystem,
  setInvitedMarkers,
  setSharedMarkers,
  setPersonalMarkers,
    setAppleFoodMarkers,
  editSharedMarkers,
  setPinpointsEvent,
  setEmptyMarkerList,
  resetMapMarkers,
  addSelectEventLocationMarker
} = UMapSlice.actions
